import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import SettingsIcon from '@mui/icons-material/Settings';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'moment/locale/fr';
import moment from 'moment';
import Form from './Form';
import Login from './Login';

const getDesignTokens = (mode) => ({
  palette: { mode, ...{ primary: { main: mode === 'light' ? '#FFD8E4' : '#73505b' } } },
});

export default function Infos() {
  moment.locale('fr');
  const [logged, setLogged] = React.useState(false);
  const [mode, setMode] = React.useState('dark');
  const [token, setToken] = React.useState();
  const [settings, setSettings] = React.useState(false);
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar enableColorOnDark position='static'>
          <Toolbar>
            <IconButton size='small' onClick={() => setMode(mode === 'dark' ? 'light' : 'dark')}>
              {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
            </IconButton>
            <Typography variant='h4' align='center' component='div' sx={{ flexGrow: 1 }}>
              Rent Receipt
            </Typography>
            {logged && (
              <IconButton size='small' onClick={() => setSettings((val) => !val)}>
                <SettingsIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <Container component='main' maxWidth='lg'>
          <Paper elevation={0} sx={{ my: { xs: 2, md: 30 }, p: { xs: 2, md: 4 } }}>
            <Grid container spacing={3} direction='column' alignItems='center' justifyContent='center'>
              {!logged && (
                <Login
                  callback={(value, jwt) => {
                    setLogged(value);
                    setToken(jwt);
                  }}
                />
              )}
              {logged && <Form token={token} settings={settings} theme={mode} />}
            </Grid>
          </Paper>
        </Container>
        <AppBar enableColorOnDark position='fixed' color='primary' sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <Typography variant='h7' align='center' component='div' sx={{ flexGrow: 1 }}>
              {!settings ? `© ${new Date().getFullYear()} - Zorikos` : `${moment(process.env.REACT_APP_BUILD_DATE).format('DD/MM/YY HH:mm:ss')} | `}
              {settings && (
                <Link color='inherit' href={`https://gitlab.com/jorisgoncalves/rent-online/-/commit/${process.env.REACT_APP_COMMIT_ID}`}>
                  {process.env?.REACT_APP_COMMIT_ID?.substring(0, 8)}
                </Link>
              )}
            </Typography>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
